export default function getTopDropPricing({
  topDropPriceType,
  topDropCity,
  registeredProduct,
}) {
  const isVancouver = topDropCity === 'vancouver';
  const isCalgary = topDropCity === 'calgary';
  if (!isVancouver && !isCalgary) {
    throw new Error(`An incorrect city is being used. [${topDropCity}]`);
  }

  const isConsumer = topDropPriceType === 'consumer';
  const isTrade = topDropPriceType === 'trade';
  if (!isConsumer && !isTrade) {
    throw new Error(
      `An incorrect priceType is being used. [${topDropPriceType}]`
    );
  }

  const isSoldByEverythingWine = registeredProduct?.everythingWine?.text;

  const price = isVancouver
    ? isConsumer
      ? isSoldByEverythingWine
        ? registeredProduct?.everythingWine?.text
        : registeredProduct?.consumer?.text
      : registeredProduct?.trade?.text
    : // Calgary pricing
    isConsumer
    ? registeredProduct?.consumerCalgary?.text
    : registeredProduct?.tradeCalgary?.text;

  if (!price || price === 'Enquire')
    return {
      price: null,
      currency: null,
    };

  return {
    price: parseFloat(price.replace('$', '')),
    currency: '$',
  };
}

/** @jsxRuntime classic */
/** @jsx jsx */
//@ts-check
import ExhibitorProductListPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorProductListPage';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import getTopDropPricing from '../../../getTopDropPricing';

export default function ExhibitorSidebar(props) {
  // console.log('props', props);
  const { exhibitor } = afterQuery(props?.data);
  return (
    <Layout>
      <ExhibitorProductListPage exhibitor={exhibitor} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query topDrop_ExhibitorProducts($partnerId: ID!, $collectionId: ID!) {
    site {
      siteMetadata {
        title
        topDropCity
        topDropPriceType
      }
    }
    exhibitor(exhibitorId: { eq: $partnerId }) {
      ...ExhibitorProductListPage
    }

    bottlebooks {
      event(eventId: $collectionId) {
        registeredProducts(filter: { partnerId: { eq: $partnerId } }) {
          nodes {
            productId
            ...topDrop_Price
          }
        }
      }
    }
  }
`;

function afterQuery(data) {
  const exhibitor = data?.exhibitor;
  const {
    siteMetadata: { topDropCity, topDropPriceType },
  } = data.site;
  const bb_registeredProducts = data?.bottlebooks?.event?.registeredProducts;
  const products = exhibitor.products?.map((product) => {
    const registeredProduct = bb_registeredProducts?.nodes.find(
      (registeredProduct) => registeredProduct.productId === product.productId
    );
    return {
      ...product,
      pricing: {
        isSoldByEverythingWine: !!registeredProduct?.everythingWine?.text,
        topDropPriceType,
        ...getTopDropPricing({
          topDropPriceType,
          topDropCity,
          registeredProduct,
        }),
      },
    };
  });

  const enhancedExhibitor = {
    ...exhibitor,
    products,
  };
  return {
    exhibitor: enhancedExhibitor,
  };
}
